@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0 0 0 30px #4bb71b;
  }
}

@keyframes move-up-with-delay {
  0% {
    padding-top: 15rem;
  }

  100% {
    padding-top: 0.7rem;
  }
}

@keyframes unscale {
  0% {
    transform: scale3d(1.1, 1.1, 1);
  }

  100% {
    transform: scale3d(0.5, 0.5, 0.5);
  }
}

@keyframes delayed-appearance {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes margin-down {
  0% {
    margin: 50px auto;
  }

  100% {
    margin: 10px auto;
  }
}

@keyframes unscale-text {
  0% {
    font-size: 1.7rem;
  }

  100% {
    font-size: 1.5rem;
  }
}

@keyframes slideInUpActionPanel {
  from {
    transform: translateY(calc(100% + 10px));
  }

  to {
    transform: translateY(0);
  }
}

@keyframes rotateCircleDarkBlue {
  from {
    transform: rotate(-180deg);
  }

  to {
    transform: rotate(0deg);
  }
}

@keyframes dashCircleDarkBlue {
  from {
    stroke-dashoffset: -337.5; /* Reduced by 2x */
  }

  to {
    stroke-dashoffset: 0;
  }
}

@keyframes tickGrayAnimation {
  from {
    stroke-dashoffset: 152; /* Reduced by 2x */
  }

  to {
    stroke-dashoffset: 0;
  }
}

@keyframes tickYellowAnimation {
  from {
    stroke-dashoffset: 152; /* Reduced by 2x */
  }

  to {
    stroke-dashoffset: 0;
  }
}

@keyframes tickGreenAnimation {
  from {
    stroke-dashoffset: 152; /* Reduced by 2x */
  }

  to {
    stroke-dashoffset: 0;
  }
}

@keyframes scale-action-spinner {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  50% {
    transform: scale(0.7);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes error-stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes error-scale {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes error-fill {
  100% {
    box-shadow: inset 0 0 0 51px #f31414; /* 30px * 1.7 */
  }
}
