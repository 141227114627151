body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  min-width: 0 !important;
}

.traveler {
  border-top: 1px dashed #000;
  text-align: center;
  font-size: 5.5vmin;
  padding: 1em;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: auto;
  grid-template-areas:
    "model model"
    "info info"
    "qr qr"
    "footer footer";
}

@media (orientation: landscape) {
  .traveler {
    grid-template-areas:
      "model info"
      "model info"
      "qr info"
      "qr footer";
  }

  .qr {
    height: 100%;
    width: auto;
  }
}

.traveler:first-child {
  border-top: 0;
}

h1 {
  font-size: 5vmin;
}

.model {
  width: 80%;
  margin: 0 auto 1em;
  grid-area: model;
}

.model img {
  width: 100%;
  max-width: 400px;
  min-width: 250px;
}

.print-info {
  grid-area: info;
  padding: 0 10px;
  word-break: break-all;
  max-width: none !important;
}

.print-info dl {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-column-gap: 0.5em;
  align-items: center;
  text-align: left;
  margin-bottom: 0;
}

.print-info dt {
  text-align: right;
  color: #444;
  font-size: 0.6em;
  font-weight: 400;
}

.print-info dd {
  margin-bottom: 0;
  font-size: 0.6em;
  font-weight: 500;
}

.print-info hr {
  grid-column: 1/3;
  width: 100%;
  color: #000;
  margin: 0;
  border-top: 2px solid #000;
}

.qr {
  min-width: 40%;
  margin: 1em auto;
  grid-area: qr;
}

.barcode {
  display: flex;
  justify-content: center;
  align-items: center;
}

.qr svg {
  width: 100%;
  height: 100%;
}

/* .barcode > a > svg { */

/*  width: 500px !important; */

/* } */

footer {
  grid-area: footer;
}

footer p {
  font-size: 4vmin;
  margin-bottom: 0;
}

.logo {
  vertical-align: middle;
  width: 1.2em;
  height: 1.2em;
}

@media print {
  @page {
    width: 100%;
    height: 100%;
    margin: 2%;
    size: auto;
  }

  .traveler {
    page-break-inside: avoid;
    page-break-after: auto;
    border-top: 0;
    padding: 0;
  }

  .model {
    margin: 0 auto 0.5em;
  }

  .print-info h1 {
    margin-bottom: 0;
  }

  .qr {
    margin: 0.5em auto 0.25em;
  }

  .barcode {
    min-width: 40%;
    grid-area: qr;
    margin: 0.5em auto 0.25em;
  }

  .barcode svg {
    width: 100%;
    height: 100%;
  }

  .hide-on-print {
    display: none;
  }
}
