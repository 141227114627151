.batch-container-list {
  text-align: left;
  width: 8em;
  margin: auto auto 1.5rem auto;
}

.batch-success-container-list {
  text-align: left;
  width: 14em;
  margin: auto auto 1.5rem auto;
}

.batch-container-with-materials-list {
  text-align: center;
  width: 20em;
  margin: auto auto 1.5rem auto;
}

.batch-container-with-materials-list > li {
  list-style: none;
}

.blend-batch-title {
  display: flex;
  margin-bottom: 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.blend-batch-container-list {
  text-align: left;
  margin: auto auto 1.5rem auto;
}

.blending-batches-list {
  white-space: nowrap;
}

.blend-batch-item {
  margin-bottom: 0.5rem;
  flex-basis: 50%;
  white-space: nowrap;
}

.blend-batch-accordion-header {
  margin-bottom: 0;
  white-space: nowrap;
}

.badge-circle {
  color: #fff;
  background: #007bff;
  border-radius: 50%;
  display: inline-block;
  width: 1.25em;
}

.success-icon {
  font-size: 10em;
  color: #28a745; /* Match to bootstrap "success" color */
}

.warning-icon {
  font-size: 10em;
  color: #f0ad4e; /* Match to bootstrap "warning" color */
}

.warning-blending-icon {
  font-size: 8em;
  color: #f0ad4e; /* Match to bootstrap "warning" color */
}

.merge-icon {
  font-size: 3em;
  color: #6c757d;
  margin: 10px 0;
}

.warning-icon-in-btn {
  color: #fff;
}

.qr-disabled-msg {
  position: absolute;
  top: 45%;
  left: 12.5%;
  width: 75%;
  text-align: center;
}

.batch-container-split-list-row {
  justify-content: space-around;
}

.blend-batch-list {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.batch-container-split-list {
  list-style: none;
  text-align: left;
  padding-left: 0;
}

.batch-container-split-list-item {
  margin-bottom: 0.25em;
}

.batch-container-split-list-btn {
  min-width: 90%;
}

.batch-material-amount {
  height: 150px;
  width: 120px;
  background: #20c997;
}

.new-quantity-label {
  margin-right: 5px;
}

.rc-collapse-motion {
  transition: height 0.3s, opacity 0.3s;
}

.rc-collapse-content-hidden {
  display: none;
}

.rc-collapse {
  background-color: #f7f7f7;
  border-radius: 3px;
  border: 1px solid #d9d9d9;
}

.rc-collapse > .rc-collapse-item {
  border-top: 1px solid #d9d9d9;
}

.rc-collapse > .rc-collapse-item:first-child {
  border-top: none;
}

.rc-collapse > .rc-collapse-item > .rc-collapse-header {
  display: flex;
  align-items: center;
  line-height: 22px;
  padding: 10px 16px;
  color: #666;
  cursor: pointer;
}

.rc-collapse > .rc-collapse-item > .rc-collapse-header .arrow {
  display: inline-block;
  content: '\20';
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 4px solid #666;
  vertical-align: middle;
  margin-right: 8px;
}

.rc-collapse > .rc-collapse-item > .rc-collapse-header .rc-collapse-extra {
  margin: 0 16px 0 auto;
}

.rc-collapse > .rc-collapse-item .rc-collapse-header-collapsible-only {
  cursor: default;
}

.rc-collapse > .rc-collapse-item .rc-collapse-header-collapsible-only .rc-collapse-header-text {
  cursor: pointer;
}

.rc-collapse > .rc-collapse-item-disabled > .rc-collapse-header {
  cursor: not-allowed;
  color: #999;
  background-color: #f3f3f3;
}

.rc-collapse-content {
  overflow: hidden;
  color: #666;
  padding: 0 16px;
  background-color: #fff;
}

.rc-collapse-content > .rc-collapse-content-box {
  margin-top: 16px;
  margin-bottom: 16px;
}

.rc-collapse-item:last-child > .rc-collapse-content {
  border-radius: 0 0 3px 3px;
}

.rc-collapse > .rc-collapse-item-active > .rc-collapse-header .arrow {
  position: relative;
  top: 2px;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #666;
  margin-right: 6px;
}

.success-text {
  font-size: 1.7rem;
  margin-bottom: 2rem;
  font-weight: 400;
  line-height: 1.2;
  animation: unscale-text 1s ease-in-out 1.5s forwards;
}

.success-action-text {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  font-weight: 400;
  line-height: 1.2;
}

.success-result-text {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.2;
}

.success-containers-total {
  margin-top: 3.5rem;
}

.success-action-bold {
  font-weight: 600;
}

.success-link-text {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  color: #4fa6d8;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}

.success-link-style {
  display: inline-block;
  color: #4fa6d8;
}

.success-link-style:hover {
  text-decoration: none;
}

.success-link-style:hover .success-link-text {
  color: #27578e;
}

.success-buttons-bottom {
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
}

.success-main {
  padding-top: 15rem;
  width: 100%;
  animation: move-up-with-delay 1s ease-in-out 1.5s forwards;
}

.success-hr-line {
  width: 40%;
}

.success-main-content {
  opacity: 0;
  animation: delayed-appearance 0.7s ease-in-out 2.4s forwards;
}

.success-animation {
  margin: 50px auto;
  animation: margin-down 1s ease-in-out 1.5s forwards;
}

.checkmark {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #4bb71b;
  stroke-miterlimit: 10;
  box-shadow: inset 0 0 0 #4bb71b;
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both, unscale 1s ease-in-out 1.5s forwards;
  position: relative;
  top: 5px;
  right: 5px;
  margin: 0 auto;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #4bb71b;
  fill: #fff;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

.truncated-materials-success-page {
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
